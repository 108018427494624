import type { ReactNode } from 'react'
import { useState } from 'react'
import './styles.scss'

interface Props {
  btnTitle: string
  children: ReactNode
}

export const DropDownMobile = ({ btnTitle, children }: Props) => {
  const [openContent, setOpenContent] = useState<boolean>(false)

  return (
    <div
      className={
        !openContent
          ? 'warranty-items_container-mob w-full bg-black'
          : 'warranty-items_container-mob w-full bg-[#1e1e1e]'
      }
    >
      <button
        className={
          !openContent
            ? 'flex flex-col items-start justify-between w-full pb-0 px-5'
            : 'flex flex-col items-start justify-between w-full pb-5 px-5'
        }
        onClick={() => setOpenContent(!openContent)}
      >
        <div
          className={
            !openContent
              ? 'flex items-center justify-between w-full mb-0'
              : 'flex items-center justify-between w-full border-dashed border-b-[1px] border-[white] mb-5'
          }
        >
          <p className="px-0 py-5 text-base text-white font-roboto">
            {btnTitle}
          </p>

          <span
            className={
              !openContent
                ? 'warranty-mob-icon warranty-mob-icon_down'
                : 'warranty-mob-icon'
            }
          />
        </div>
        {openContent && <div>{children}</div>}
      </button>
    </div>
  )
}
