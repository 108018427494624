interface Props {
  codeTitle: string
  code: string
  warrantyTitle: string
  warranty: string
  warrantyClubTitle: string
  warrantyClub: string
}

export const ContentMob = ({
  codeTitle,
  code,
  warrantyTitle,
  warranty,
  warrantyClubTitle,
  warrantyClub,
}: Props) => {
  return (
    <div>
      <div className="warranty-items_content flex items-start text-left flex-col font-roboto">
        <div>
          <p className="px-0 py-5 text-base text-white font-roboto">
            {codeTitle}: <span>{code}</span>
          </p>
          <p className="px-0 py-5 text-base text-white font-roboto">
            {warrantyTitle}: <span>{warranty}</span>
          </p>
          <p className="px-0 py-5 text-base text-white font-roboto">
            {warrantyClubTitle}: <span>{warrantyClub}</span>
          </p>
        </div>
      </div>
    </div>
  )
}
