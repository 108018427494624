export const DataMob = [
  {
    items: [
      {
        btnTitle: 'Mochila',
        content: [
          {
            codeTitle: 'Código',
            code: '8502159',
            warrantyTitle: 'Garantia Comum',
            warranty: '10 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 11 anos',
          },
        ],
      },
      {
        btnTitle: 'Mochila de Trilha NH500',
        content: [
          {
            codeTitle: 'Código',
            code: '8383594',
            warrantyTitle: 'Garantia Comum',
            warranty: '10 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 11 anos',
          },
        ],
      },
      {
        btnTitle: 'Mochila de Trilha NH500 30 L',
        content: [
          {
            codeTitle: 'Código',
            code: '8383599',
            warrantyTitle: 'Garantia Comum',
            warranty: '10 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 11 anos',
          },
        ],
      },
      {
        btnTitle: 'Mochila de Trilha NH100 20 L',
        content: [
          {
            codeTitle: 'Código',
            code: '8502141',
            warrantyTitle: 'Garantia Comum',
            warranty: '10 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 11 anos',
          },
        ],
      },
      {
        btnTitle: 'Mochila de Viagem Travel 100 60 L',
        content: [
          {
            codeTitle: 'Código',
            code: '8554568',
            warrantyTitle: 'Garantia Comum',
            warranty: '10 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 11 anos',
          },
        ],
      },
      {
        btnTitle: 'Elíptico Vertical EL120',
        content: [
          {
            codeTitle: 'Código',
            code: '8484396',
            warrantyTitle: 'Garantia Comum',
            warranty: '2 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 3 anos',
          },
        ],
      },
      {
        btnTitle: 'Barraca de Trilha Arpenaz 2XL Fresh & Black',
        content: [
          {
            codeTitle: 'Código',
            code: '8492485',
            warrantyTitle: 'Garantia Comum',
            warranty: '2 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 3 anos',
          },
        ],
      },
      {
        btnTitle: 'Barraca de Trilha Arpenaz 2 Fresh & Black',
        content: [
          {
            codeTitle: 'Código',
            code: '8357354',
            warrantyTitle: 'Garantia Comum',
            warranty: '2 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 3 anos',
          },
        ],
      },
      {
        btnTitle: 'Bicicleta Ergométrica Autoalimentada BIKE 500',
        content: [
          {
            codeTitle: 'Código',
            code: '8503088',
            warrantyTitle: 'Garantia Comum',
            warranty: '2 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 3 anos',
          },
        ],
      },
      {
        btnTitle: 'Bicicleta Ergométrica Essential Domyos',
        content: [
          {
            codeTitle: 'Código',
            code: '8364829',
            warrantyTitle: 'Garantia Comum',
            warranty: '2 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 3 anos',
          },
        ],
      },
      {
        btnTitle: 'Mochila Masculina de Trekking Escape 70 L',
        content: [
          {
            codeTitle: 'Código',
            code: '8383137',
            warrantyTitle: 'Garantia Comum',
            warranty: '10 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 11 anos',
          },
        ],
      },
      {
        btnTitle: 'Mochila',
        content: [
          {
            codeTitle: 'Código',
            code: '8502148',
            warrantyTitle: 'Garantia Comum',
            warranty: '10 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 11 anos',
          },
        ],
      },
      {
        btnTitle: 'Mochila de Trilha NH100 30 L',
        content: [
          {
            codeTitle: 'Código',
            code: '8550766',
            warrantyTitle: 'Garantia Comum',
            warranty: '10 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 11 anos',
          },
        ],
      },
      {
        btnTitle: 'Abrigo de Camping Tarp Fresh Multifunções',
        content: [
          {
            codeTitle: 'Código',
            code: '8572388',
            warrantyTitle: 'Garantia Comum',
            warranty: '2 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 3 anos',
          },
        ],
      },
      {
        btnTitle: 'Mochila de Viagem Travel 100 40 L',
        content: [
          {
            codeTitle: 'Código',
            code: '8554561',
            warrantyTitle: 'Garantia Comum',
            warranty: '10 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 11 anos',
          },
        ],
      },
      {
        btnTitle: 'Bicicleta Ergométrica EB140',
        content: [
          {
            codeTitle: '8543290',
            code: '8502159',
            warrantyTitle: 'Garantia Comum',
            warranty: '2 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 3 anos',
          },
        ],
      },
      {
        btnTitle: 'Mochila de Viagem Travel 100 40 L',
        content: [
          {
            codeTitle: 'Código',
            code: '8554562',
            warrantyTitle: 'Garantia Comum',
            warranty: '10 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 11 anos',
          },
        ],
      },
      {
        btnTitle: 'Barraca de Trekking Trek 900',
        content: [
          {
            codeTitle: 'Código',
            code: '8492462',
            warrantyTitle: 'Garantia Comum',
            warranty: '2 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 3 anos',
          },
        ],
      },
      {
        btnTitle: 'Mochila',
        content: [
          {
            codeTitle: 'Código',
            code: '8382940',
            warrantyTitle: 'Garantia Comum',
            warranty: '10 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 11 anos',
          },
        ],
      },
      {
        btnTitle: 'Esteira de Corrida e Caminhada T500B',
        content: [
          {
            codeTitle: 'Código',
            code: '8561210',
            warrantyTitle: 'Garantia Comum',
            warranty: '2 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 3 anos',
          },
        ],
      },
      {
        btnTitle: 'Mochila de Trilha NH100 10 L',
        content: [
          {
            codeTitle: 'Código',
            code: '8502149',
            warrantyTitle: 'Garantia Comum',
            warranty: '10 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 11 anos',
          },
        ],
      },
      {
        btnTitle: 'Bicicleta Ergométrica Horizontal E-Seat',
        content: [
          {
            codeTitle: 'Código',
            code: '8372431',
            warrantyTitle: 'Garantia Comum',
            warranty: '2 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 3 anos',
          },
        ],
      },
      {
        btnTitle: 'Mochila de Trilha NH500 20 L',
        content: [
          {
            codeTitle: 'Código',
            code: '8383596',
            warrantyTitle: 'Garantia Comum',
            warranty: '10 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 11 anos',
          },
        ],
      },
      {
        btnTitle: 'Mochila de Trilha NH100 30 L',
        content: [
          {
            codeTitle: 'Código',
            code: '8502161',
            warrantyTitle: 'Garantia Comum',
            warranty: '10 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 11 anos',
          },
        ],
      },
      {
        btnTitle: 'Barraca de Trilha 2 Seconds Easy',
        content: [
          {
            codeTitle: 'Código',
            code: '8492687',
            warrantyTitle: 'Garantia Comum',
            warranty: '2 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 3 anos',
          },
        ],
      },
      {
        btnTitle: 'Mochila de Trilha NH100 10 L',
        content: [
          {
            codeTitle: 'Código',
            code: '8502156',
            warrantyTitle: 'Garantia Comum',
            warranty: '10 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 11 anos',
          },
        ],
      },
      {
        btnTitle: 'Barraca de Trilha Arpenaz 3 Fresh & Black',
        content: [
          {
            codeTitle: 'Código',
            code: '8357355',
            warrantyTitle: 'Garantia Comum',
            warranty: '2 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 3 anos',
          },
        ],
      },
      {
        btnTitle: 'Esteira de Corrida e Caminhada T500B',
        content: [
          {
            codeTitle: 'Código',
            code: '8550264',
            warrantyTitle: 'Garantia Comum',
            warranty: '2 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 3 anos',
          },
        ],
      },
      {
        btnTitle: 'Barraca de Trilha Arpenaz 2',
        content: [
          {
            codeTitle: 'Código',
            code: '8293539',
            warrantyTitle: 'Garantia Comum',
            warranty: '2 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 3 anos',
          },
        ],
      },
      {
        btnTitle: 'Bolsa de Trasnporte Viagem 80/120 L Quechua',
        content: [
          {
            codeTitle: 'Código',
            code: '8383302',
            warrantyTitle: 'Garantia Comum',
            warranty: '10 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 11 anos',
          },
        ],
      },
      {
        btnTitle: 'Barraca de Trilha 2 Seconds Fresh & Black',
        content: [
          {
            codeTitle: 'Código',
            code: '8357353',
            warrantyTitle: 'Garantia Comum',
            warranty: '2 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 3 anos',
          },
        ],
      },
      {
        btnTitle: 'Mochila de Trilha NH100 20 L',
        content: [
          {
            codeTitle: 'Código',
            code: '8529019',
            warrantyTitle: 'Garantia Comum',
            warranty: '10 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 11 anos',
          },
        ],
      },
      {
        btnTitle: 'Mochila de Caminhada na Natureza NH100 10 L',
        content: [
          {
            codeTitle: 'Código',
            code: '8551452',
            warrantyTitle: 'Garantia Comum',
            warranty: '10 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 11 anos',
          },
        ],
      },
      {
        btnTitle: 'Mochila Feminina de Trekking Escape 50 L',
        content: [
          {
            codeTitle: 'Código',
            code: '8383138',
            warrantyTitle: 'Garantia Comum',
            warranty: '10 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 11 anos',
          },
        ],
      },
      {
        btnTitle: 'Barraca de Trilha 2 Seconds Fresh & Black',
        content: [
          {
            codeTitle: 'Código',
            code: '8357352',
            warrantyTitle: 'Garantia Comum',
            warranty: '2 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 3 anos',
          },
        ],
      },
      {
        btnTitle: 'Equipamento',
        content: [
          {
            codeTitle: 'Código',
            code: '8509114',
            warrantyTitle: 'Garantia Comum',
            warranty: '2 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 3 anos',
          },
        ],
      },
      {
        btnTitle: 'Elíptico de Autoalimentação 520',
        content: [
          {
            codeTitle: 'Código',
            code: '8503081',
            warrantyTitle: 'Garantia Comum',
            warranty: '2 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 3 anos',
          },
        ],
      },
      {
        btnTitle: 'Mochila de Trilha NH100 20 L',
        content: [
          {
            codeTitle: 'Código',
            code: '8529020',
            warrantyTitle: 'Garantia Comum',
            warranty: '10 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 11 anos',
          },
        ],
      },
      {
        btnTitle: 'Bolsa de Transporte Trekking 40 L',
        content: [
          {
            codeTitle: 'Código',
            code: '8243256',
            warrantyTitle: 'Garantia Comum',
            warranty: '10 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 11 anos',
          },
        ],
      },
      {
        btnTitle: 'Bicicleta Estática Bike 900',
        content: [
          {
            codeTitle: 'Código',
            code: '8542560',
            warrantyTitle: 'Garantia Comum',
            warranty: '2 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 3 anos',
          },
        ],
      },
      {
        btnTitle: 'Barraca',
        content: [
          {
            codeTitle: 'Código',
            code: '8378283',
            warrantyTitle: 'Garantia Comum',
            warranty: '2 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 3 anos',
          },
        ],
      },
      {
        btnTitle: 'Barraca de Trilha 2 Seconds Easy',
        content: [
          {
            codeTitle: 'Código',
            code: '8492461',
            warrantyTitle: 'Garantia Comum',
            warranty: '2 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 3 anos',
          },
        ],
      },
      {
        btnTitle: 'Barraca de Trilha 2 Seconds 3XL Fresh & Black',
        content: [
          {
            codeTitle: 'Código',
            code: '8492484',
            warrantyTitle: 'Garantia Comum',
            warranty: '2 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 3 anos',
          },
        ],
      },
      {
        btnTitle: 'Elíptico 100 Domyos',
        content: [
          {
            codeTitle: 'Código',
            code: '8398006',
            warrantyTitle: 'Garantia Comum',
            warranty: '2 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 3 anos',
          },
        ],
      },
      {
        btnTitle: 'Máquina de Remo ROWER120',
        content: [
          {
            codeTitle: 'Código',
            code: '8501452',
            warrantyTitle: 'Garantia Comum',
            warranty: '2 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 3 anos',
          },
        ],
      },
      {
        btnTitle: 'Esteira T540',
        content: [
          {
            codeTitle: 'Código',
            code: '8590138',
            warrantyTitle: 'Garantia Comum',
            warranty: '2 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 3 anos',
          },
        ],
      },
      {
        btnTitle: 'Bolsa de Tranporte Trekking Viagem Extend 40 a 60 L',
        content: [
          {
            codeTitle: 'Código',
            code: '8383300',
            warrantyTitle: 'Garantia Comum',
            warranty: '10 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 11 anos',
          },
        ],
      },
      {
        btnTitle: 'Barraca',
        content: [
          {
            codeTitle: 'Código',
            code: '8386029',
            warrantyTitle: 'Garantia Comum',
            warranty: '2 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 3 anos',
          },
        ],
      },
      {
        btnTitle: 'Mochila de Trilha NH100 20 L',
        content: [
          {
            codeTitle: 'Código',
            code: '8502137',
            warrantyTitle: 'Garantia Comum',
            warranty: '10 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 11 anos',
          },
        ],
      },
      {
        btnTitle: 'Aparelho de Remo FRW 500',
        content: [
          {
            codeTitle: 'Código',
            code: '8487893',
            warrantyTitle: 'Garantia Comum',
            warranty: '2 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 3 anos',
          },
        ],
      },
      {
        btnTitle: 'Barraca',
        content: [
          {
            codeTitle: 'Código',
            code: '8245650',
            warrantyTitle: 'Garantia Comum',
            warranty: '2 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 3 anos',
          },
        ],
      },
      {
        btnTitle: 'Barraca',
        content: [
          {
            codeTitle: 'Código',
            code: '8386028',
            warrantyTitle: 'Garantia Comum',
            warranty: '2 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 3 anos',
          },
        ],
      },
      {
        btnTitle: 'Mochila',
        content: [
          {
            codeTitle: 'Código',
            code: '8502151',
            warrantyTitle: 'Garantia Comum',
            warranty: '10 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 11 anos',
          },
        ],
      },
      {
        btnTitle: 'Remo Ultracompacto 100',
        content: [
          {
            codeTitle: 'Código',
            code: '8527501',
            warrantyTitle: 'Garantia Comum',
            warranty: '2 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 3 anos',
          },
        ],
      },
      {
        btnTitle: 'Barraca de Trilha 2 Seconds 2XL Fresh & Black',
        content: [
          {
            codeTitle: 'Código',
            code: '8492483',
            warrantyTitle: 'Garantia Comum',
            warranty: '2 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 3 anos',
          },
        ],
      },
      {
        btnTitle: 'Mochila de Trilha NH100 10 L',
        content: [
          {
            codeTitle: 'Código',
            code: '8502157',
            warrantyTitle: 'Garantia Comum',
            warranty: '10 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 11 anos',
          },
        ],
      },
      {
        btnTitle: 'Mochila de Trilha NH500 20 L',
        content: [
          {
            codeTitle: 'Código',
            code: '8383597',
            warrantyTitle: 'Garantia Comum',
            warranty: '10 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 11 anos',
          },
        ],
      },
      {
        btnTitle: 'Esteira Ergométrica T540 Domyos',
        content: [
          {
            codeTitle: 'Código',
            code: '8542702',
            warrantyTitle: 'Garantia Comum',
            warranty: '2 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 3 anos',
          },
        ],
      },
      {
        btnTitle: 'Esteira Ergométrica Weslo Cadence G5.9',
        content: [
          {
            codeTitle: 'Código',
            code: '8260602',
            warrantyTitle: 'Garantia Comum',
            warranty: '2 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 3 anos',
          },
        ],
      },
      {
        btnTitle: 'Mochila Feminina de Trekking Escape 70 L',
        content: [
          {
            codeTitle: 'Código',
            code: '8383139',
            warrantyTitle: 'Garantia Comum',
            warranty: '10 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 11 anos',
          },
        ],
      },
      {
        btnTitle: 'Mochila',
        content: [
          {
            codeTitle: 'Código',
            code: '8529006',
            warrantyTitle: 'Garantia Comum',
            warranty: '10 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 11 anos',
          },
        ],
      },
      {
        btnTitle: 'Mochila de Trilha NH500 30 L Quechua',
        content: [
          {
            codeTitle: 'Código',
            code: '8383598',
            warrantyTitle: 'Garantia Comum',
            warranty: '10 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 11 anos',
          },
        ],
      },
      {
        btnTitle: 'Barraca',
        content: [
          {
            codeTitle: 'Código',
            code: '8322006',
            warrantyTitle: 'Garantia Comum',
            warranty: '2 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 3 anos',
          },
        ],
      },
      {
        btnTitle: 'Mochila de Viagem Travel 60 L',
        content: [
          {
            codeTitle: 'Código',
            code: '8554567',
            warrantyTitle: 'Garantia Comum',
            warranty: '10 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 11 anos',
          },
        ],
      },
      {
        btnTitle: 'Mochila',
        content: [
          {
            codeTitle: 'Código',
            code: '8331243',
            warrantyTitle: 'Garantia Comum',
            warranty: '10 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 11 anos',
          },
        ],
      },
      {
        btnTitle: 'Mochila de Trilha NH100 20 L',
        content: [
          {
            codeTitle: 'Código',
            code: '8529024',
            warrantyTitle: 'Garantia Comum',
            warranty: '10 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 11 anos',
          },
        ],
      },
      {
        btnTitle: 'Mochila de Trilha NH500',
        content: [
          {
            codeTitle: 'Código',
            code: '8383595',
            warrantyTitle: 'Garantia Comum',
            warranty: '10 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 11 anos',
          },
        ],
      },
      {
        btnTitle: 'Mochila Masculina de Trekking Escape 50 L',
        content: [
          {
            codeTitle: 'Código',
            code: '8383140',
            warrantyTitle: 'Garantia Comum',
            warranty: '10 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 11 anos',
          },
        ],
      },
      {
        btnTitle: 'Barraca de Trilha Arpenaz 3 XL Fresh & Black',
        content: [
          {
            codeTitle: 'Código',
            code: '8492486',
            warrantyTitle: 'Garantia Comum',
            warranty: '2 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 3 anos',
          },
        ],
      },
      {
        btnTitle: 'Elíptico Vertical FEL 500',
        content: [
          {
            codeTitle: 'Código',
            code: '8528961',
            warrantyTitle: 'Garantia Comum',
            warranty: '2 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 3 anos',
          },
        ],
      },
      {
        btnTitle: 'Barraca',
        content: [
          {
            codeTitle: 'Código',
            code: '8383300',
            warrantyTitle: 'Garantia Comum',
            warranty: '2 anos',
            warrantyClubTitle: 'Garantia para cadastrados',
            warrantyClub: '+ 1 ano = 3 anos',
          },
        ],
      },
    ],
  },
]
