export type Title = {
  title: string
  content: Content[]
}

export type Content = {
  item: string
}

export const DataInfo: Title[] = [
  {
    title: 'Nome',
    content: [
      { item: 'Mochila' },
      { item: 'Mochila de Trilha NH500' },
      { item: 'Mochila de Trilha NH500 30 L Quechua' },
      { item: 'Mochila de Trilha NH100 20 L' },
      { item: 'Mochila de Viagem Travel 100 60 L' },
      { item: 'Elíptico Vertical EL120' },
      { item: 'Barraca de Trilha Arpenaz 2XL Fresh & Black' },
      { item: 'Barraca de Trilha Arpenaz 2 Fresh & Black' },
      { item: 'Bicicleta Ergométrica Autoalimentada BIKE 500' },
      { item: 'Bicicleta Ergométrica Essential Domyos' },
      { item: 'Mochila Masculina de Trekking Escape 70 L' },
      { item: 'Mochila' },
      { item: 'Mochila de Trilha NH100 30 L' },
      { item: 'Abrigo de Camping Tarp Fresh Multifunções' },
      { item: 'Mochila de Viagem Travel 100 40 L' },
      { item: 'Bicicleta Ergométrica EB140' },
      { item: 'Mochila de Viagem Travel 100 40 L' },
      { item: 'Barraca de Trekking Trek 900' },
      { item: 'Mochila' },
      { item: 'Esteira de Corrida e Caminhada T500B' },
      { item: 'Mochila de Trilha NH100 10 L' },
      { item: 'Bicicleta Ergométrica Horizontal E-Seat' },
      { item: 'Mochila de Trilha NH500 20 L' },
      { item: 'Mochila de Trilha NH100 30 L' },
      { item: 'Barraca de Trilha 2 Seconds Easy' },
      { item: 'Mochila de Trilha NH100 10 L' },
      { item: 'Barraca de Trilha Arpenaz 3 Fresh & Black' },
      { item: 'Esteira de Corrida e Caminhada T500B' },
      { item: 'Barraca de Trilha Arpenaz 2' },
      { item: 'Bolsa de Trasnporte Viagem 80/120 L Quechua' },
      { item: 'Barraca de Trilha 2 Seconds Fresh & Black' },
      { item: 'Mochila de Trilha NH100 20 L' },
      { item: 'Mochila de Caminhada na Natureza NH100 10 L' },
      { item: 'Mochila Feminina de Trekking Escape 50 L' },
      { item: 'Barraca de Trilha 2 Seconds Fresh & Black' },
      { item: 'Equipamento' },
      { item: 'Elíptico de Autoalimentação 520' },
      { item: 'Mochila de Trilha NH100 20 L' },
      { item: 'Bolsa de Transporte Trekking 40 L' },
      { item: 'Bicicleta Estática Bike 900' },
      { item: 'Barraca' },
      { item: 'Barraca de Trilha 2 Seconds Easy' },
      { item: 'Barraca de Trilha 2 Seconds 3XL Fresh & Black' },
      { item: 'Elíptico 100 Domyos' },
      { item: 'Máquina de Remo ROWER120' },
      { item: 'Esteira T540' },
      { item: 'Bolsa de Tranporte Trekking Viagem Extend 40 a 60 L' },
      { item: 'Barraca' },
      { item: 'Mochila de Trilha NH100 20 L' },
      { item: 'Aparelho de Remo FRW 500' },
      { item: 'Barraca' },
      { item: 'Barraca' },
      { item: 'Mochila' },
      { item: 'Remo Ultracompacto 100' },
      { item: 'Barraca de Trilha 2 Seconds 2XL Fresh & Black' },
      { item: 'Mochila de Trilha NH100 10 L' },
      { item: 'Mochila de Trilha NH500 20 L' },
      { item: 'Esteira Ergométrica T540 Domyos' },
      { item: 'Esteira Ergométrica Weslo Cadence G5.9' },
      { item: 'Mochila Feminina de Trekking Escape 70 L' },
      { item: 'Mochila' },
      { item: 'Mochila de Trilha NH500 30 L Quechua' },
      { item: 'Barraca' },
      { item: 'Mochila de Viagem Travel 60 L' },
      { item: 'Mochila' },
      { item: 'Mochila de Trilha NH100 20 L' },
      { item: 'Mochila de Trilha NH500' },
      { item: 'Mochila Masculina de Trekking Escape 50 L' },
      { item: 'Barraca de Trilha Arpenaz 3 XL Fresh & Black' },
      { item: 'Elíptico Vertical FEL 500' },
      { item: 'Barraca' },
    ],
  },
  {
    title: 'Código',
    content: [
      { item: '8502159' },
      { item: '8383594' },
      { item: '8383599' },
      { item: '8502141' },
      { item: '8554568' },
      { item: '8484396' },
      { item: '8492485' },
      { item: '8357354' },
      { item: '8503088' },
      { item: '8364829' },
      { item: '8383137' },
      { item: '8502148' },
      { item: '8550766' },
      { item: '8572388' },
      { item: '8554561' },
      { item: '8543290' },
      { item: '8554562' },
      { item: '8492462' },
      { item: '8382940' },
      { item: '8561210' },
      { item: '8502149' },
      { item: '8372431' },
      { item: '8383596' },
      { item: '8502161' },
      { item: '8492687' },
      { item: '8502156' },
      { item: '8357355' },
      { item: '8550264' },
      { item: '8293539' },
      { item: '8383302' },
      { item: '8357353' },
      { item: '8529019' },
      { item: '8551452' },
      { item: '8383138' },
      { item: '8357352' },
      { item: '8509114' },
      { item: '8503081' },
      { item: '8529020' },
      { item: '8243256' },
      { item: '8542560' },
      { item: '8378283' },
      { item: '8492461' },
      { item: '8492484' },
      { item: '8398006' },
      { item: '8501452' },
      { item: '8590138' },
      { item: '8383300' },
      { item: '8386029' },
      { item: '8502137' },
      { item: '8487893' },
      { item: '8245650' },
      { item: '8386028' },
      { item: '8502151' },
      { item: '8527501' },
      { item: '8492483' },
      { item: '8502157' },
      { item: '8383597' },
      { item: '8542702' },
      { item: '8260602' },
      { item: '8383139' },
      { item: '8529006' },
      { item: '8383598' },
      { item: '8322006' },
      { item: '8554567' },
      { item: '8331243' },
      { item: '8529024' },
      { item: '8383595' },
      { item: '8383140' },
      { item: '8492486' },
      { item: '8528961' },
      { item: '8384157' },
    ],
  },
  {
    title: 'Garantia Comum',
    content: [
      { item: '10 anos' },
      { item: '10 anos' },
      { item: '10 anos' },
      { item: '10 anos' },
      { item: '10 anos' },
      { item: '2 anos' },
      { item: '2 anos' },
      { item: '2 anos' },
      { item: '2 anos' },
      { item: '2 anos' },
      { item: '10 anos' },
      { item: '10 anos' },
      { item: '10 anos' },
      { item: '2 anos' },
      { item: '10 anos' },
      { item: '2 anos' },
      { item: '10 anos' },
      { item: '2 anos' },
      { item: '10 anos' },
      { item: '2 anos' },
      { item: '10 anos' },
      { item: '2 anos' },
      { item: '10 anos' },
      { item: '10 anos' },
      { item: '2 anos' },
      { item: '10 anos' },
      { item: '2 anos' },
      { item: '2 anos' },
      { item: '2 anos' },
      { item: '10 anos' },
      { item: '2 anos' },
      { item: '10 anos' },
      { item: '10 anos' },
      { item: '10 anos' },
      { item: '2 anos' },
      { item: '2 anos' },
      { item: '2 anos' },
      { item: '10 anos' },
      { item: '10 anos' },
      { item: '2 anos' },
      { item: '2 anos' },
      { item: '2 anos' },
      { item: '2 anos' },
      { item: '2 anos' },
      { item: '2 anos' },
      { item: '2 anos' },
      { item: '10 anos' },
      { item: '2 anos' },
      { item: '10 anos' },
      { item: '2 anos' },
      { item: '2 anos' },
      { item: '2 anos' },
      { item: '10 anos' },
      { item: '2 anos' },
      { item: '2 anos' },
      { item: '10 anos' },
      { item: '10 anos' },
      { item: '2 anos' },
      { item: '2 anos' },
      { item: '10 anos' },
      { item: '10 anos' },
      { item: '10 anos' },
      { item: '2 anos' },
      { item: '10 anos' },
      { item: '10 anos' },
      { item: '10 anos' },
      { item: '10 anos' },
      { item: '10 anos' },
      { item: '2 anos' },
      { item: '2 anos' },
      { item: '2 anos' },
    ],
  },
  {
    title: 'Garantia para cadastrados no Clube',
    content: [
      { item: '+ 1 ano = 11 anos' },
      { item: '+ 1 ano = 11 anos' },
      { item: '+ 1 ano = 11 anos' },
      { item: '+ 1 ano = 11 anos' },
      { item: '+ 1 ano = 11 anos' },
      { item: '+ 1 ano = 3 anos' },
      { item: '+ 1 ano = 3 anos' },
      { item: '+ 1 ano = 3 anos' },
      { item: '+ 1 ano = 3 anos' },
      { item: '+ 1 ano = 3 anos' },
      { item: '+ 1 ano = 11 anos' },
      { item: '+ 1 ano = 11 anos' },
      { item: '+ 1 ano = 11 anos' },
      { item: '+ 1 ano = 3 anos' },
      { item: '+ 1 ano = 11 anos' },
      { item: '+ 1 ano = 3 anos' },
      { item: '+ 1 ano = 11 anos' },
      { item: '+ 1 ano = 3 anos' },
      { item: '+ 1 ano = 11 anos' },
      { item: '+ 1 ano = 3 anos' },
      { item: '+ 1 ano = 11 anos' },
      { item: '+ 1 ano = 3 anos' },
      { item: '+ 1 ano = 11 anos' },
      { item: '+ 1 ano = 11 anos' },
      { item: '+ 1 ano = 3 anos' },
      { item: '+ 1 ano = 11 anos' },
      { item: '+ 1 ano = 3 anos' },
      { item: '+ 1 ano = 3 anos' },
      { item: '+ 1 ano = 3 anos' },
      { item: '+ 1 ano = 11 anos' },
      { item: '+ 1 ano = 3 anos' },
      { item: '+ 1 ano = 11 anos' },
      { item: '+ 1 ano = 11 anos' },
      { item: '+ 1 ano = 11 anos' },
      { item: '+ 1 ano = 3 anos' },
      { item: '+ 1 ano = 3 anos' },
      { item: '+ 1 ano = 3 anos' },
      { item: '+ 1 ano = 11 anos' },
      { item: '+ 1 ano = 11 anos' },
      { item: '+ 1 ano = 3 anos' },
      { item: '+ 1 ano = 3 anos' },
      { item: '+ 1 ano = 3 anos' },
      { item: '+ 1 ano = 3 anos' },
      { item: '+ 1 ano = 3 anos' },
      { item: '+ 1 ano = 3 anos' },
      { item: '+ 1 ano = 3 anos' },
      { item: '+ 1 ano = 11 anos' },
      { item: '+ 1 ano = 3 anos' },
      { item: '+ 1 ano = 11 anos' },
      { item: '+ 1 ano = 3 anos' },
      { item: '+ 1 ano = 3 anos' },
      { item: '+ 1 ano = 3 anos' },
      { item: '+ 1 ano = 11 anos' },
      { item: '+ 1 ano = 3 anos' },
      { item: '+ 1 ano = 3 anos' },
      { item: '+ 1 ano = 11 anos' },
      { item: '+ 1 ano = 11 anos' },
      { item: '+ 1 ano = 3 anos' },
      { item: '+ 1 ano = 3 anos' },
      { item: '+ 1 ano = 11 anos' },
      { item: '+ 1 ano = 3 anos' },
      { item: '+ 1 ano = 11 anos' },
      { item: '+ 1 ano = 3 anos' },
      { item: '+ 1 ano = 11 anos' },
      { item: '+ 1 ano = 11 anos' },
      { item: '+ 1 ano = 11 anos' },
      { item: '+ 1 ano = 11 anos' },
      { item: '+ 1 ano = 11 anos' },
      { item: '+ 1 ano = 3 anos' },
      { item: '+ 1 ano = 3 anos' },
      { item: '+ 1 ano = 3 anos' },
    ],
  },
]
