import { DataInfo } from './dataInfo'
import type { Content, Title } from './dataInfo'

export const TableList = () => {
  return (
    <div className="warranty-items_container max-h-[400px] overflow-auto pr-2">
      <table className="warranty-items flex border-collapse w-full font-roboto overflow-x-hidden">
        {DataInfo.map((item: Title, indexItem: number) => {
          return (
            <tbody key={indexItem}>
              <tr>
                <th>{item.title}</th>
              </tr>
              {item.content.map((info: Content, indexContent) => {
                return (
                  <tr key={indexContent}>
                    <td>{info.item}</td>
                  </tr>
                )
              })}
            </tbody>
          )
        })}
      </table>
    </div>
  )
}
